// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.
@import './mat-table';
@import './spinner';
@import './mat-card';
@import './dropzone';

@include mat.all-component-typographies();
@include mat.core();

/* You can add global styles to this file, and also import other style files */
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}
.mat-mdc-cell {
  box-sizing: content-box;
  border-bottom-color: #0000001f !important;
}
.mat-mdc-header-cell {
  box-sizing: content-box;
  border-bottom-color: #0000001f !important;

  margin-left: 0;
  margin-right: 0;
  font-weight: 500 !important;
  font-size: 12px !important;
  color: #0000008a !important;
}

.mat-mdc-cell {
  font-size: 14px !important;
  // font-weight: 400 !important;
  color: #000000de !important;
}

mat-sidenav {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent !important;
}

.mat-mdc-form-field-focus-overlay {
  background-color: transparent !important;
}
// mat-paginator {
//   --mdc-outlined-text-field-outline-color: transparent;
//   --mdc-outlined-text-field-hover-outline-color: transparent;
//   --mdc-outlined-text-field-focus-outline-color: transparent;
// }
.mat-mdc-nav-list .mat-mdc-list-item {
  border-radius: 13px !important;
}

mat-divider {
  border-color: #0000001f !important;
}

button {
  border-radius: 10px !important;
}

a.mdc-button {
  border-radius: 10px !important;
}

mat-accordion {
  --mat-expansion-header-text-size: 1rem;
  --mat-expansion-header-text-weight: 400;
}

.mat-expansion-panel-body {
  padding-bottom: 0 !important;
}

.search-field {
  width: 170px;
}

.header-action-button {
  margin-top: 15px;
  height: 35px;
}

.header-actions-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}
