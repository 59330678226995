@use 'sass:map';
@use '@angular/material' as mat;

// Mixin to generate theme styles
@mixin generate-theme($theme-name, $palettes) {
  $_rest: (
    secondary: map.get($palettes, secondary),
    neutral: map.get($palettes, neutral),
    neutral-variant: map.get($palettes, neutral-variant),
    error: map.get($palettes, error),
  );
  $_primary: map.merge(map.get($palettes, primary), $_rest);
  $_tertiary: map.merge(map.get($palettes, tertiary), $_rest);

  $theme: mat.define-theme(
    (
      color: (
        theme-type: light,
        primary: $_primary,
        tertiary: $_tertiary,
      ),
      // typography: mat.define-typography-config(),
      density:
        (
          scale: 0,
        ),
    )
  );
  
  :root {
    --app-chart-color-1: #{mat.get-theme-color($theme, error, 60)};
    --app-chart-color-2: #{mat.get-theme-color($theme, secondary, 60)};
  }

  .#{$theme-name} {
    @include mat.all-component-themes($theme);
    @include mat.color-variants-backwards-compatibility($theme);
    @include mat.typography-hierarchy($theme, $back-compat: true);

    mat-toolbar {
      --mat-toolbar-container-background-color: #{mat.get-theme-color(
          $theme,
          primary,
          40
        )};
      --mat-toolbar-container-text-color: #{mat.get-theme-color(
          $theme,
          neutral,
          100
        )};
      mat-icon {
        color: #{mat.get-theme-color($theme, neutral, 100)};
      }
    }
    mat-card {
      .header {
        background-color: #{mat.get-theme-color($theme, primary, 40)};
        color: #{mat.get-theme-color($theme, neutral, 100)};
      }
    }

    .mat-accent {
      color: #{mat.get-theme-color($theme, secondary, 70)} !important;
    }
    .document-list-actions {
      --mdc-icon-button-icon-color: #{mat.get-theme-color($theme, secondary, 60)};
      --mdc-icon-button-disabled-icon-color: #{mat.get-theme-color(
          $theme,
          neutral,
          50
        )};
    }

    --mdc-button-disabled-color: #{mat.get-theme-color($theme, neutral, 50)};

    .loader-rect {
      background-color: #{mat.get-theme-color($theme, primary, 60)};
    }
  }
}
